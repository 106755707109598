import algoliasearch from 'algoliasearch/lite';
import React from 'react'
import { InstantSearch } from 'react-instantsearch-dom';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { linkResolver } from "../utils/linkResolver"
import { Link } from "gatsby"
import algoliaLogo from "../images/logo-algolia-nebula-blue-full.svg"

const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY  
);


const Autocomplete = ({ hits, currentRefinement, refine }) => (
  <ul>
    <li>
      <input
        id="search"
        type="search"
        value={currentRefinement}
        onChange={event => {
            refine(event.currentTarget.value);
            
        }}
        className="
        block 
        w-full 
        pl-10 
        pr-3 
        py-2 
        border 
        border-gray-300 
        rounded-md 
        leading-5 
        bg-white 
        placeholder-gray-500 
        focus:outline-none 
        focus:placeholder-gray-400 
        focus:shadow-outline-blue 
        sm:text-sm 
        transition 
        duration-150 
        ease-in-out" 
        placeholder="Suchen" 

      />
    </li>

    <div className={`${currentRefinement ? 'block' : 'hidden'} absolute -mt-1 border-b border-r border-l bg-white w-full z-10 px-8 py-2 shadow-lg rounded-b-lg`}>
    {hits.map(hit => (
        <li key={hit.objectID} className="py-1 px-2 text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-blue-100 ">
            <Link 
                to={linkResolver(hit._meta)}
                className=""
                
            >{hit.title} <span className="text-gray-400 text-xs tracking-wider">{hit.lesson_type.toUpperCase()}</span></Link>
        </li>
    ))}
    <div className="mt-1 pt-1 text-xs flex items-center justify-end">Search by <img src={algoliaLogo} alt="Algolia Logo" className="ml-2 h-3 w-auto" /></div>
    </div>
  </ul>
);

const CustomAutocomplete = connectAutoComplete(Autocomplete);


export default function LessonSearch() {
    return (
        <InstantSearch indexName="LESSONS" searchClient={searchClient}>
            <CustomAutocomplete />
        </InstantSearch>
    )
}

