import React from "react"
import Header from "../components/header"
import SEO from "../components/seo"

import "../styles/layout.css"
import "../styles/richtext.css"
import "../styles/vimeo.css"
import "../styles/fonts.css"
import scribble from "../images/scribble.png"

const Layout = ({ children, title }) => {
  
  return (
    <div className="antialiased">
      <SEO title={title} />
      <Header />
      <div 
        style={{
          backgroundImage: `url(${scribble}), linear-gradient(to right, #EDA041 50%, #E3796D 50%)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed'
        }}
        className="pt-4 text-base font-light text-gray-900"
      >
        
        <main className="relative bg-white max-w-6xl mx-auto shadow-xl">{children}</main>
        <footer className="max-w-6xl mx-auto px-4 py-4 tracking-wide">
          © {new Date().getFullYear()}, Copyright by Candide Schokoladen GmbH
        </footer>
      </div>
    </div>
  )
}


export default Layout