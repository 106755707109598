import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const SEO = ({ description, lang, meta, keywords, title, image }) => {
  
  const defaultTitle = 'Lieblingsrezepte für daheim von Daniel Budde | Candide Schokoladen, Berlin'
  const defaultAuthor = '@candideberlin'
  const defaultDescription = 'Inklusive Techniken und jede Menge Hintergrundwissen.'
  const defaultImage = `https://learn.candide.berlin/images/smiley.png`;
 
  const metaTitle = title || 'Neu aus Berlin';
  const metaDescription = description || defaultDescription;
  const metaImage = image || defaultImage;

  return (
    <Helmet 
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: defaultAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
      .concat(meta)}
    >
    </ Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  title: null,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;