import { Link } from "gatsby"
import React, { useState } from 'react'
import Search from "./search";
import smiley from "../images/candide_smiley.png"

const Header = () => {

  const [state, setState] = useState({
    mobileDrawer: false,

  });


  const toggleMobileDrawer = () => {
      setState({
          ...state,
          mobileDrawer: !state.mobileDrawer,
      });
  };



  return (
      <>
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex px-2 lg:px-0">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" >
                  <img className="block h-12 w-auto" src={smiley} alt="" />
                </Link>
              </div>
              <div className="hidden lg:ml-6 lg:flex">
                <Link to="/" activeClassName="border-b-2 border-canred hover:border-canred" className="font-caps uppercase tracking-widest text-sm inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out">
                  Rezepte
                </Link>
              </div>
            </div>
            <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
              <div className="max-w-lg w-full lg:max-w-xs">
                <label htmlFor="search" className="sr-only">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <Search />
                </div>
              </div>
            </div>
            <div className="flex items-center lg:hidden">
              <button onClick={toggleMobileDrawer} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path className={ state.mobileDrawer ? 'hidden' : 'inline-flex'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  <path className={ state.mobileDrawer ? 'inline-flex' : 'hidden'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="hidden lg:ml-4 lg:flex">
              <Link to="/glossary" activeClassName="border-b-2 border-canred hover:border-canred" className="font-caps uppercase tracking-widest text-sm inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out">
                  Verzeichnis
              </Link>
            </div>
          </div>
        </div>
        <div className={`${state.mobileDrawer ? 'block' : 'hidden'} lg:hidden`}>
          <div className="pt-2 pb-3">
            <Link to="/" activeClassName="py-3 border-l-4 border-canred text-gray-900 bg-gray-50" className="block pl-3 pr-4 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out">Rezepte</Link>
            <Link to="/glossary" activeClassName="py-3 border-l-4 border-canred text-gray-900 bg-gray-50" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out">Verzeichnis</Link>
          </div>
        </div>
      </nav>
    </>
  )
}


export default Header
